import PageHead from '../../../components/PageHead'
import { t } from '../../../utils'
import DevicesLimitModal from '../../../components/modals/DevicesLimitModal'
import styles from './_labLayout.module.scss'
import LabHeader from './LabHeader'
import LabFooter from './LabFooter'
import FreeTrialBanner from '../../../components/FreeTrialBanner'

interface LayoutProps {
  pageTitle?: string
  pageDescription?: string
  pageKeywords?: string
  children?: React.ReactNode
}

const LabLayout: React.FC<LayoutProps> = ({
  children,
  pageTitle = t('WorshipOnline - Lab Plus'),
  pageDescription,
  pageKeywords
}) => {
  return (
    <>
      <DevicesLimitModal />
      <FreeTrialBanner />
      <PageHead
        title={pageTitle}
        description={pageDescription}
        keywords={pageKeywords}
        noindex={true}
      />
      <LabHeader />
      <div className={styles.mainContainer}>{children}</div>
      <LabFooter />
    </>
  )
}

export default LabLayout
